<template>
    <modal ref="modalCambiarValor" titulo="Cambiar valor en todos los cedis" tamano="modal-lg" icon="aprobar" no-aceptar adicional="Cambiar" @adicional="cambiarValoresCedis">
        <ValidationObserver ref="validator">
            <div class="row mx-0 justify-center">
                <div v-for="(cedi,c) in cedisValidos" :key="c" class="col-10 px-0 d-flex mb-3">
                    <p class="col text-general f-15">
                        {{ cedi.nombre }}
                    </p>
                    <ValidationProvider
                    v-if="cedi.disponible_cedi"
                    v-slot="{errors}"
                    :vid="'cp'+c"
                    :rules="`required|greaterThanZero|max_value:16777215|promo:${cedi.promo_valor},${cedi.promo_valor_formateado}`"
                    name="valor"
                    tag="div"
                    class="col px-0 text-general position-relative"
                    >
                        <div class="d-middle border br-6 w-100" style="height:38px;">
                            <input-miles-moneda v-model="cedi.valor" :id-moneda="cedi.idm_moneda" border maxlength="13" class="w-100" />
                            <div class="py-1 d-middle-center px-1 mr-1 h-100">
                                {{ cedi.sigla }}
                            </div>
                        </div>
                        <p class="text-danger f-11 pl-2"> {{ errors[0] }} </p>
                    </ValidationProvider>
                    <div v-else class="col px-0 text-general position-relative">
                        <div class="d-middle border br-6 w-100 bg-whitesmoke text-gris" style="height:38px;">
                            <span class="mx-2 ml-3">-</span>
                            <div class="py-1 d-middle-center px-1 mr-1 h-100 ml-auto">
                                {{ cedi.sigla }}
                            </div>
                        </div>
                    </div>
                    <div class="col-1 px-2 pt-2">
                        <div v-if="cedi.id_promocion != null" class="btn-action text-general2 cr-pointer br-8" @click="eliminarPromocion(cedi)">
                            <el-tooltip placement="bottom" content="Eliminar promoción" effect="light">
                                <i class="icon-tag-remove" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import CedisProductos from '~/services/cedis/cedis_productos'
export default {
    data(){
        return {
            loading: false,
            idProducto: null,
            cedisValidos: [],
            checked: false
        }
    },
    methods: {
        toggle({cedisValidos, idProducto}){
            this.idProducto = idProducto
            this.cedisValidos = _.cloneDeep(cedisValidos)
            this.$refs.modalCambiarValor.toggle();
        },
        async cambiarValoresCedis(){
            try {
                const valid = await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                let cedis = this.cedisValidos.filter(el => el.disponible_cedi).map(el => {
                    const cedi = {
                        id_cedis: el.id,
                        id_producto: this.idProducto,
                        valor: el.valor,
                    }
                    return cedi
                })
                const model = {
                    cedis
                }

                const {data} = await CedisProductos.cambiarValoresCedis(model)
                this.$refs.modalCambiarValor.toggle();
                this.notificacion('Mensaje', data.Message, 'success')

            } catch (error){
                this.error_catch(error)
            }finally {
                this.loading = false
            }
        },
        async eliminarPromocion(cedi){
            try {
                const params = {
                    id_cedis: cedi.id,
                    id_producto: this.idProducto,
                    id_promocion: cedi.id_promocion,
                }
                this.loading = true
                const {data} = await CedisProductos.eliminarPromocion(params)
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                cedi.id_promocion = null
                cedi.promo_valor = null
            } catch (e){
                this.error_catch(e)
            }finally {
                this.loading = false
            }
        },
    }
}
</script>
